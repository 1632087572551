
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ViewMixin from "@/mixins/Crud/ViewMixin";
import SendMailToContactForm from "@/components/forms/domain/SendMailToContactForm.vue";
import IDomain from "@/interfaces/IDomain";
import AppConfig from "@/config/AppConfig";
import { mixins } from "vue-class-component";
import { DomainStoreMixin } from "@/mixins/Stores/DomainStoreMixin";
import { SslCertStoreMixin } from "@/mixins/Stores/SslCertStoreMixin";

const DomainModule = namespace("domain");

@Component({
  components: { SendMailToContactForm },
  mixins: [ViewMixin],
})
export default class DomainView extends mixins(
  DomainStoreMixin,
  SslCertStoreMixin
) {
  private name = "DomainView";

  protected resource = AppConfig.DOMAIN_RESOURCE;
  protected descriptionField = "url";
  protected redirectRouteNameOnDeleteSuccess = "DomainList";

  protected isSendMailToContactModalVisible = false;
  protected sendMailToContactModalDomain: IDomain | null = null;
  protected files: Array<string> = [];
  protected isFilesLoading = false;

  protected isCheckingDomain = false;

  @DomainModule.Action("del")
  protected removeAction: any;

  protected onAdd(): void {
    this.$router.push({ name: "DomainCreate" });
  }

  protected onSendMailToContact(domain: IDomain): void {
    console.log("onSendMailToContact", domain);

    this.files = [];
    this.fetchCertificateFiles(domain);
    this.sendMailToContactModalDomain = domain;
    this.isSendMailToContactModalVisible = true;
  }

  protected onSendMailFormAction(domain: IDomain, mailItem: any) {
    this.$crudService
      .create({
        resource: [
          AppConfig.DOMAIN_RESOURCE,
          String(domain.id),
          "send-mail",
        ].join("/"),
        data: mailItem,
      })
      .then(() => {
        this.isSendMailToContactModalVisible = false;
      });
  }

  protected fetchCertificateFiles(domain: IDomain | null): void {
    if (domain) {
      this.isFilesLoading = true;
      this.$crudService
        .findAll({
          resource:
            AppConfig.SSL_CERTIFICATE_RESOURCE +
            "/" +
            domain.ssl_certificate_id +
            "/files",
        })
        .then((response: any) => {
          console.log("files fetched:", response.data);
          this.files = response.data;
        })
        .catch((error: any) => {
          console.error("files fetch error", error.response);
        })
        .finally(() => {
          this.isFilesLoading = false;
        });
    }
  }

  protected onCheckDomainClicked(): void {
    if (this.getDomainItem) {
      this.onCheckDomain(this.getDomainItem);
    }
  }

  protected onCheckDomain(domain: IDomain): void {
    this.checkDomain(domain);
  }

  protected checkDomain(domain: IDomain) {
    this.isCheckingDomain = true;
    this.$apiService
      .put(AppConfig.DOMAIN_RESOURCE + "/" + domain.id + "/check-domain", {})
      .then((response: any) => {
        this.loadSSlCertItem();
      })
      .finally(() => {
        this.isCheckingDomain = false;
      });
  }

  protected loadSSlCertItem(): void {
    if (
      !this.getSslCertList ||
      Array.isArray(this.getSslCertList) ||
      this.getSslCertList.length <= 0 ||
      this.getSslCertList.id !== this.getDomainItem.ssl_certificate_id
    ) {
      if (this.getDomainItem) {
        this.findAllSslCertItemsAction({
          resource:
            this.resource +
            "/" +
            this.getDomainItem.id +
            AppConfig.SSL_CERTIFICATE_RESOURCE,
        });
      }
    }
  }
}
