
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import IDomain from "@/interfaces/IDomain";
import AppConfig from "@/config/AppConfig";
import FilesSelect from "@/components/form_inputs/FilesSelect.vue";
import { mixins } from "vue-class-component";
import { UserStoreMixin } from "@/mixins/Stores/UserStoreMixin";
import IUser from "@/mixins/interface/IUser";
import { AxiosResponse } from "axios";

@Component({
  components: { FilesSelect },
})
export default class SendMailToContactForm extends mixins(UserStoreMixin) {
  protected name = "SendMailToContactForm";

  @Prop({ required: true })
  protected domain!: IDomain | null;

  @Prop({ required: false, default: () => [] })
  protected files!: Array<string>;

  @Prop({ required: false, default: false })
  protected filesLoading!: boolean;

  @Watch("domain", { immediate: true, deep: true })
  protected onDomainChanged(domain: IDomain): void {
    this.resetForm();
    if (domain) {
      //  this.fetchCertificate();
      if (domain.responsible_user_id) {
        this.loadDomainResponsible(domain.responsible_user_id);
      }

      this.mailTo = domain.contact_email;
      this.subject = String(
        this.$t(
          "modules.domain.send_mail_to_contact_modal.subject_text_request",
          { domain: domain.url }
        )
      );
      this.selectedFiles = [];
    }
  }

  @Watch("getUserList", { immediate: true, deep: true })
  onUserListChanged(users: Array<IUser> | null) {
    if (users) {
      this.mailCcData = users.map((user: IUser) => user.email);
    }
  }

  protected mailTo = "";
  protected mailCc: Array<string> = [];
  protected mailCcData: Array<string> = [];
  protected mailCCDataLoading = false;
  protected answerTo = "";
  protected subject = "";
  protected mailFrom = "service-support@media-it-services.de";
  //protected files: Array<any> = [];
  protected selectedFiles: Array<any> = [];

  protected customMailText = "";

  protected get mailItem(): any {
    const mailItem: any = {
      mail_to: this.mailTo,
      subject: this.subject,
      from: this.mailFrom,
      mail_text: this.mailtext,
      attachments: this.selectedFiles,
      mail_cc: this.mailCc,
    };

    if (this.answerTo && this.answerTo !== "") {
      mailItem["answer_to"] = this.answerTo;
    }

    return mailItem;
  }

  protected resetForm(): void {
    this.mailTo = "";
    this.mailCc = [];
    this.answerTo = "";
    this.subject = "";
    this.selectedFiles = [];
  }

  protected get mailtext(): string {
    if (this.customMailText !== "") {
      return this.customMailText;
    }
    if (this.domain) {
      return String(
        this.$t(
          "modules.domain.send_mail_to_contact_modal.text_template.default",
          { contact: this.domain.contact_email, domain: this.domain.url }
        )
      );
    }
    return "";
  }

  protected set mailtext(mailtext: string) {
    this.customMailText = mailtext;
  }

  protected onSendMailClicked(): void {
    if (this.domain) {
      console.debug("onSendMailClicked", this.mailItem);
      this.$emit("send-mail", this.domain, this.mailItem);
    }
  }

  protected filterMailCcData(filterText: string) {
    console.debug("filter cc mails ", filterText, this.mailCcData);
    if (this.getUserList && Array.isArray(this.getUserList)) {
      this.mailCcData = this.getUserList
        .map((user: IUser) => user.email)
        .filter((email: string) => {
          console.log(
            "filtering ",
            email,
            email.toLowerCase(),
            email.toLowerCase().indexOf(filterText.toLowerCase()),
            filterText.toLowerCase()
          );
          return email.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
        });
      return;
    }
    this.mailCcData = [];
  }

  protected loadDomainResponsible(userId: number): void {
    this.mailCCDataLoading = true;
    this.$crudService
      .findOne({
        resource: AppConfig.USRE_RESOURCE,
        id: userId,
      })
      .then((response: AxiosResponse) => {
        if (response.data && response.data.email) {
          this.mailCc.push(response.data.email);
        }
      })
      .finally(() => {
        this.mailCCDataLoading = false;
      });
  }

  created() {
    console.debug("SendMail created", this.$authService.currentUser);
    if (this.$authService.currentUser && this.$authService.currentUser.email) {
      this.answerTo = this.$authService.currentUser.email;
    }
    if (this.getUserList.length < 1) {
      this.findAllUserItemsAction({
        resource: AppConfig.USRE_RESOURCE,
      });
    }
  }
}
