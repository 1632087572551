
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class FilesSelect extends Vue {
  protected name = "FilesSelect";

  @Prop({ required: true })
  protected files!: Array<string>;

  @Prop({ required: false, default: false })
  protected loading!: boolean;

  @Prop({ required: false, default: false })
  protected disabled!: boolean;

  @Prop({ required: false, default: "" })
  protected placeholder!: string;

  @VModel()
  protected selectedFiles!: Array<string>;

  protected parseFilename(filename: string) {
    return filename.split("/")[filename.split("/").length - 1];
  }

  protected get maxSelectFileCount(): number {
    return 5;
  }
}
