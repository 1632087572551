var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"is-fullwidth"},[_c('form',[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.$t("modules.domain.send_mail_to_contact_modal.title"))+" ")]),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}})]),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('b-field',{attrs:{"label":String(_vm.$t('general.mail_to')),"label-position":"on-border"}},[_c('b-input',{attrs:{"type":"email","required":""},model:{value:(_vm.mailTo),callback:function ($$v) {_vm.mailTo=$$v},expression:"mailTo"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":String(_vm.$t('general.mail_cc')),"label-position":"on-border"}},[_c('b-taginput',{attrs:{"maxtags":5,"data":_vm.mailCcData,"allow-new":true,"allow-duplicates":false,"open-on-focus":true,"type":"is-info","disabled":_vm.mailCCDataLoading,"loading":_vm.mailCCDataLoading,"placeholder":String(
                    _vm.$t(
                      'modules.domain.send_mail_to_contact_modal.click_here_to_select_cc'
                    )
                  )},on:{"typing":_vm.filterMailCcData},model:{value:(_vm.mailCc),callback:function ($$v) {_vm.mailCc=$$v},expression:"mailCc"}})],1)],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('b-field',{attrs:{"label":String(
                  _vm.$t('modules.domain.send_mail_to_contact_modal.from_label')
                ),"label-position":"on-border","disabled":true}},[_c('b-input',{attrs:{"type":"email"},model:{value:(_vm.mailFrom),callback:function ($$v) {_vm.mailFrom=$$v},expression:"mailFrom"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":String(
                  _vm.$t(
                    'modules.domain.send_mail_to_contact_modal.answer_to_label'
                  )
                ),"label-position":"on-border"}},[_c('b-input',{attrs:{"type":"email"},model:{value:(_vm.answerTo),callback:function ($$v) {_vm.answerTo=$$v},expression:"answerTo"}})],1)],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',[_c('b-field',{attrs:{"label":String(
                    _vm.$t(
                      'modules.domain.send_mail_to_contact_modal.subject_text_label'
                    )
                  ),"label-position":"on-border"}},[_c('b-input',{attrs:{"type":"text","required":""},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1)],1),_c('div',{staticClass:"mt-3"},[(_vm.files.length > 0 || _vm.filesLoading)?_c('FilesSelect',{attrs:{"files":_vm.files,"loading":_vm.filesLoading,"placeholder":String(
                    _vm.$t(
                      'modules.domain.send_mail_to_contact_modal.click_here_to_select_files'
                    )
                  )},model:{value:(_vm.selectedFiles),callback:function ($$v) {_vm.selectedFiles=$$v},expression:"selectedFiles"}}):_c('div',{staticClass:"notification is-warning is-light"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t( "modules.domain.send_mail_to_contact_modal.no_files_available_warning" ))+" ")]),_c('div',{staticClass:"is-fullwidth has-text-centered mt-2"},[_c('router-link',{attrs:{"to":{
                      name: 'SslCertificateCsrCreate',
                      params: { id: _vm.domain.ssl_certificate_id },
                    },"custom":""}},[_c('b-button',[_vm._v(_vm._s(_vm.$t( "modules.domain.send_mail_to_contact_modal.button_create_csr" )))])],1)],1)])],1),_c('b-field',{attrs:{"label":String(
                  _vm.$t(
                    'modules.domain.send_mail_to_contact_modal.mailtext_label'
                  )
                ),"disabled":true}},[_c('b-input',{attrs:{"type":"textarea","rows":"15"},model:{value:(_vm.mailtext),callback:function ($$v) {_vm.mailtext=$$v},expression:"mailtext"}})],1)],1)])]),_c('footer',{staticClass:"modal-card-foot"},[_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"is-right",attrs:{"type":"is-primary","icon-left":"fas fa-paper-plane"},on:{"click":_vm.onSendMailClicked}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }